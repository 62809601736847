import { FC, useState } from 'react';
import { themeColors } from 'assets/theme/style';
import Notification from 'components/Notification';
import InfoIcon from 'assets/icons/InfoIcon';
import { RootStateOrAny, useSelector } from 'react-redux';
import { AuthUpdateBannerContainer } from './styled';

const NotificationBanner: FC = () => {
  const [showBanner, setShowBanner] = useState<boolean>(true);

  const {
    loading: appLoading,
  }: {
    loading: boolean;
  } = useSelector((state: RootStateOrAny) => state.app);

  return !appLoading && showBanner ? (
    <Notification
      noShadow
      centeredButton
      hasClosed
      closeHandler={() => setShowBanner(false)}
      backgroundColor={themeColors.bg.errorMuted}
      color={themeColors.text.primary}
      icon={<InfoIcon />}
      descriptionJSX={
        <AuthUpdateBannerContainer>
          <p>
            <strong>Exciting News!</strong> Kanexa is releasing major updates to our platform in November 2024! More details to follow.
          </p>
          <p>For any questions, contact Kanexa Support at <a href="mailto:support@kanexa.com">support@kanexa.com</a>.</p>
        </AuthUpdateBannerContainer>
      }
    />
  ) : (
    <></>
  );
};

export default NotificationBanner;
